import { createContext, useContext, useEffect, useState } from 'react'
import Text from 'forms/fields/Text'
import MultiCheckbox from 'forms/fields/MultiCheckbox'
import PolicyDrivers from 'forms/fields/PolicyDrivers'
import { AccountContext, FormContext } from 'utils/context'
import PrincipalDrivers from 'forms/fieldsets/general/PrincipalDrivers'
import { useTranslation } from 'react-i18next'

export const driversDisable = createContext({})

const OwnerDriver = () => {

  const { t } = useTranslation()
  const { drivers, vehicles } = useContext(AccountContext)
  const { data, setFieldValue, clearAllErrors } = useContext(FormContext)
  
  const isDriverAdd = data['type'] === 'driver_add'

  const driversToShow = drivers.filter(d => data.policy === d.policy)
  if (isDriverAdd) {
    driversToShow.push({driver_id: 1, full_name: `${data['first_name']}  ${data['last_name']}`})
  }
  
  const choices = driversToShow.map(d => [d.driver_id, d.full_name])
  choices.push([0, t('Other')])
  
  const [dataLoading, setDataLoading] = useState(false)

  const RegOwnersCallback = value => {
    if (value.includes(0)) {
      clearAllErrors()
      setFieldValue('registered_owner_other', null)
    }
  }

  useEffect(() => {
    if (data.registered_owners) {
      let ownerInPolicy = driversToShow.some(d => data.registered_owners[0] === d.driver_id)
      if (!ownerInPolicy) {
        setFieldValue('registered_owners', [])
      }
    }
  }, []) // eslint-disable-line
  
  let vehiclesOnPolicy = []
  data.principal_drivers.forEach(d => vehiclesOnPolicy.push(vehicles.filter(v => v.vehicle_id === d.vehicle_id)[0].vehicle_id))
    
  let driverEnabled = {}
  driversToShow.forEach(d => driverEnabled[d.driver_id]=true)
  
  if(vehiclesOnPolicy.length && !dataLoading) {
    setDataLoading(true)
  }
  
  let vehiclesWithDrivers = {}
  
  /*
  * The format of vehiclesWithDriversState is
  * {
  *   vehicle_id: driver_id, (for driver selected on that vehicle)
  *   vehicle_id: driver_id,
  *   ...
  * }
  * This is set in a useEffect below
  */
  const [vehiclesWithDriversState, setVehiclesWithDriversState] = useState(vehiclesWithDrivers)
  
  let vehiclesAllDrivers = {}
  
  /*
  * The format of results is
  * {
  *   vehicle_id: {
  *                 driver_id: true/false,
  *                 driver_id: true/false,
  *                 ...
  *               },
  *   vehicle_id: {
  *                 driver_id: true/false,
  *                 driver_id: true/false,
  *                 ...
  *               },
  *   ...
  * }
  * This is set in a useEffect below
  */
  const [results, setResults] = useState(vehiclesAllDrivers)
  
  useEffect(() => {
    vehiclesOnPolicy.forEach(v => {
      vehiclesAllDrivers[v] = driverEnabled
    })
    if (!isDriverAdd){
      vehiclesAllDrivers["newCar"] = driverEnabled;
    }
    setResults(vehiclesAllDrivers)
    
    vehiclesOnPolicy.forEach(v => vehiclesWithDrivers[v] = null)
    if (!isDriverAdd){
      vehiclesWithDrivers['newCar'] = null
    }
    setVehiclesWithDriversState(vehiclesWithDrivers)
  }, [dataLoading]) // eslint-disable-line
  
  const changeCallBack = (vehicleId, val) => {
    setVehiclesWithDriversState((prev) => {
      return {
        ...prev,
        [vehicleId]: val
      }
    })
  }
  
  /*
  * DO NOT TOUCH THIS CODE UNLESS YOU REALLY HAVE TO (CURRENTLY RUNNING ON HOPES AND DREAMS)
  * Every time a new driver selection is made the vehiclesWithDriversState is updated causing the useEffect to run,
  * The useEffect first calculates what the max amount of times a driver can be assigned to a car is.
  * Max amount is calulated by taking the max between 0 and (number of vehicles - number of drivers) + 1
  * If we have vehicles >= drivers than each driver can be assigned either once or more depending on number of cars
  * If we have vehicles < drivers than a driver can only be assigned one time 
  * The Algo then adds to the drivers count each time they are selected until one of the drivers reaches the max amount.
  * Once max amount is reached the driver is disabled everywhere and the algo will allow only remaining drivers to be selected
  * The core algorithm works by setting a true or false value in an object where the keys are the driver ids
  */
 
  useEffect(() => {
    let maxAssignments = Math.max(0, Object.keys(vehiclesWithDriversState).length - driversToShow.length) + 1;
    
    // Determine how many assignments each driver has
    const driverAssignmentsCount = {};
    let maxReached = false;
    Object.keys(vehiclesWithDriversState).forEach((key) => {
      const driver = vehiclesWithDriversState[key];
      if (!driver) {
        return;
      }
      if (driverAssignmentsCount[driver]) {
        driverAssignmentsCount[driver]++
      } else {
        driverAssignmentsCount[driver] = 1
      }

      if (driverAssignmentsCount[driver] >= maxAssignments) {
        maxReached = true;
      }
    })
    // Enable/disable buttons based on each drivers assignments
    setResults((prev) => {
      const disabledDrivers = {}
      Object.keys(prev).forEach((vehicle)=> {
        const drivers = prev[vehicle];
        disabledDrivers[vehicle] = {};
        Object.keys(drivers).forEach((driver) => {
          const count = driverAssignmentsCount[driver] || 0;
          const currentDriver = vehiclesWithDriversState[vehicle];
          if (maxReached && count > 0 && parseInt(currentDriver) !== parseInt(driver)) {
            disabledDrivers[vehicle][driver] = false;
          } else {
            disabledDrivers[vehicle][driver] = true;
          }
        })
      })
      return disabledDrivers;
    })   
  }, [vehiclesWithDriversState]) // eslint-disable-line
  
  console.log(results)

  return (
    <driversDisable.Provider value={{results}}>
      <fieldset>
        {!isDriverAdd && <MultiCheckbox
          name="registered_owners"
          label={t("Who will be the registered owner(s)?")}
          ChangeCallback={RegOwnersCallback}
          choices={choices}
        />}
        {data['registered_owners'] && data['registered_owners'].includes(0) && (
          <Text
            name="registered_owner_other"
            label={t("Other")}
          />
        )}
        <PrincipalDrivers helpCopy={t("Selection does not guarantee how drivers' are assigned. If changes are required you will be notified.")} callBack={changeCallBack}/>
        {!isDriverAdd && <strong className="form-title">{`${data.year} ${data.make} ${data.model}`}</strong>}
        {!isDriverAdd && <PolicyDrivers
          name="principal_driver"
          callBack={changeCallBack}
          Stacked
        />}
      </fieldset>
    </driversDisable.Provider>
  )
}

export default OwnerDriver